import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import 'luxd/src/style.css';
import '@streamlux/animation-editor/src/style.css';

import Section from "../sections/section";
export const query = graphql`
    query($slug: String!) {
        contentfulLayout(slug: { eq: $slug }) {
            id
            slug
            title
            description
            contentful_id
            contentModule {
                ... on Node {
                    id
                }
            }
        }
    }
`;

export default function PageTemplate({ data }) {
    const title = data.contentfulLayout.title;
    const description = data.contentfulLayout.description;
    const contentModule = data.contentfulLayout.contentModule;

    return (
        <Layout title={title}>
            <SEO title={title} description={description} />
            {contentModule &&
                contentModule.length > 0 &&
                contentModule.map(content => (
                    <Section
                        contentModuleId={content.id}
                        type={content.__typename}
                        key={content.id}
                    />
                ))}
        </Layout>
    );
}
